<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showDialog"
      width="1200px"
      top="3%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog"
    >
      <span
        class="content-box"
        :style="{ '--max-height': tableMaxHeight + 'px' }"
      >
        <el-form label-width="auto" :inline="false" size="small">
          <el-row :gutter="rowSpan">
            <el-col :span="rowSpan / 3">
              <el-form-item label="商品" required>
                <DfShopGoods
                  v-if="!submitData.GrouponSettingId"
                  ref="DfShopGoods"
                  :isGroupon="1"
                  :appendToBody="true"
                  @selected="onSelectGoods"
                ></DfShopGoods>
                <span v-else>{{ submitData.ItemName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="rowSpan / 3">
              <el-form-item label="上架" required>
                <el-radio-group v-model="submitData.OnSale" @change="">
                  <el-radio
                    v-for="item in [
                      { Name: '是', Value: 1 },
                      { Name: '否', Value: 0 },
                    ]"
                    :key="item.Value"
                    :label="item.Value"
                  >
                    {{ item.Name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="rowSpan / 3">
              <el-form-item label="未成团处理" required>
                <el-radio-group v-model="submitData.FailNextMode">
                  <el-radio
                    v-for="item in failNextModeSelector"
                    :key="item.Value"
                    :label="item.Value"
                  >
                    {{ item.Name }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="rowSpan / 3">
              <el-form-item label="成团时效" required>
                <el-input
                  style="width: 140px;"
                  v-model="submitData.GrouponHours"
                  clearable
                >
                  <template slot="append">小时</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="rowSpan / 3">
              <el-form-item label="开团时间" required>
                <el-date-picker
                  v-model="multipleDate"
                  type="datetimerange"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  @change="onChangeDate"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="rowSpan">
            <el-col :span="rowSpan / 3">
              <el-form-item label="拼团限购" required>
                <el-input
                  style="width: 140px;"
                  v-model="submitData.LimitBuyCount"
                  @change="submitData.LimitBuyCount = $event < 0 ? 0 : $event"
                ></el-input>
                <span class="tips">0为不限购</span>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>

        <div class="df-row">
          <div class="tips">
            <el-icon class="el-icon-warning"></el-icon>
            <span>设置拼团商品成团人数以及各规格商品的销售价</span>
          </div>
        </div>

        <el-tabs
          v-model="activeIndex"
          type="border-card"
          @tab-click="onAddTab"
          @tab-remove="deleteGrouponPrice"
        >
          <el-tab-pane
            v-for="(personItem, personIndex) in submitData.PersonGroupons"
            :key="personIndex"
            :label="personItem.PersonCount + '人团'"
            :name="'per' + personIndex"
            closable
          >
            <div
              class="groupon-item df-row"
              v-for="(item, index) in personItem.PriceItems"
              :key="item.SkuId"
            >
              <div class="df-col attr">{{ item.SkuAttrNames || "无规格" }}</div>
              <div class="df-col">{{ item.GrouponPersons + "人" }}</div>
              <div class="df-col price">
                <el-input
                  v-model="item.GrouponPrice"
                  placeholder=""
                  size="small"
                  @input="item.Modified = 1"
                >
                  <template slot="prepend">¥</template>
                </el-input>
              </div>
              <div class="df-col">
                <el-checkbox
                  v-model="item.OnSale"
                  :true-label="1"
                  :false-label="0"
                  :indeterminate="false"
                  @change="item.Modified = 1"
                  >参与拼团</el-checkbox
                >
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="+ 新增" :name="'add'">
            <el-form label-width="auto" :inline="false" size="medium">
              <el-form-item label="成团人数" required>
                <el-input-number
                  v-model="personCount"
                  :min="2"
                  :max="5"
                  label="请输入2~5的数字"
                ></el-input-number>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="initGrouponPriceList"
                  >确定</el-button
                >
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </span>
      <span slot="footer">
        <el-button @click="onCloseDialog">取 消</el-button>
        <el-button
          type="primary"
          :disabled="saveDiabled"
          @click="saveGrouponSetting"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Shop from "@/api/shop.js";

export default {
  data() {
    return {
      rowSpan: 21,
      tableMaxHeight: 0,
      activeIndex: "",
      personCount: 2,
      title: "",
      submitData: {
        GrouponSettingId: 0,
        ItemId: -1,
        GrouponHours: 24,
        LimitBuyCount: 0,
        SaleBeginTime: "",
        SaleEndTime: "",
        FailNextMode: 0,
        PersonGroupons: [],
      },
      multipleDate: [],
      failNextModeSelector: [],
      showDialog: false,
      saveDiabled: false,
      delDisabled: false,
    };
  },

  created() {
    // this.getResize();
  },

  mounted() {
    // window.addEventListener("resize", this.getResize);
    this.resetData();
  },

  destroyed() {
    // window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    // getResize() {
    //   let { clientHeight } = this.util.getDocumentElementInfo();
    //   this.tableMaxHeight = clientHeight - (100 + 40 + 58 * 3);
    // },

    // 初始化拼团设置
    async initGrouponSetting() {
      try {
        let { data } = await Shop.initGrouponSetting({
          groupon_setting_id: this.submitData.GrouponSettingId,
        });
        this.submitData = data.editor;
        if (!data.editor) {
          this.resetData();
        }
        this.failNextModeSelector = data.failNextModeSelector;
        this.multipleDate = [
          this.submitData.SaleBeginTime,
          this.submitData.SaleEndTime,
        ];
        this.activeIndex = "per0";
        console.log(this.submitData);
      } catch (err) {
        console.log(err);
      }
    },

    // 初始化拼团价格
    async initGrouponPriceList() {
      if (!this.personCount) {
        return this.$message.warning("请输入成团人数");
      }
      try {
        let { data } = await Shop.initGrouponPriceList({
          item_id: this.submitData.ItemId,
          person_count: this.personCount,
        });
        this.submitData.PersonGroupons.push(data);
        this.activeIndex = `per${this.submitData.PersonGroupons.length - 1}`;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存拼团设置
    async saveGrouponSetting() {
      let { submitData } = this,
        { PersonGroupons } = submitData;

      if (!submitData.ItemId) {
        return this.$message.warning("请选择商品");
      } else if (!submitData.GrouponHours) {
        return this.$message.warning("请输入拼团时长");
        // } else if (!submitData.LimitBuyCount) {
        //   return this.$message.warning("请输入限购数量");
      } else if (!submitData.SaleBeginTime) {
        return this.$message.warning("请选择开始时间");
      } else if (!submitData.SaleEndTime) {
        return this.$message.warning("请选择结束时间");
        // } else if (!submitData.FailNextMode) {
      }

      for (let i = 0; i < PersonGroupons.length; i++) {
        for (let j = 0; j < PersonGroupons[i].PriceItems.length; j++) {
          if (!PersonGroupons[i].PriceItems[j].GrouponPrice) {
            this.$message.warning(
              `${PersonGroupons[i].PersonCount}人团 【${PersonGroupons[i]
                .PriceItems[j].SkuAttrNames || "无规格"}】 价格不能为0`
            );
            return;
          }
        }
      }

      this.saveDiabled = true;
      try {
        let { errcode } = await Shop.saveGrouponSetting(this.submitData);
        if (errcode == 0) {
          this.$message.success("保存成功");
          this.$emit("success");
          this.resetData();
          this.onCloseDialog();
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.saveDiabled = false;
      }
    },

    // 删除拼团价格
    deleteGrouponPrice(event) {
      let { submitData } = this;
      let activeIndex = Number(event.replace("per", ""));
      let param = submitData.PersonGroupons[activeIndex];
      // console.log(param);
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.delDisabled = true;
        try {
          let { errcode } = await Shop.deleteGrouponPrice({
            groupon_setting_id: submitData.GrouponSettingId,
            person_count: param.PersonCount,
          });
          if (errcode == 0) {
            this.$message.success("删除成功");
            this.activeIndex =
              activeIndex == 0 ? "per0" : `per${activeIndex - 1}`;
            this.submitData.PersonGroupons.splice(activeIndex, 1);
          }
        } catch (err) {
          console.log(err);
        } finally {
          this.delDisabled = false;
        }
      });
    },

    // 选择商品回调事件
    onSelectGoods(event) {
      // console.log(event);
      if (!event.goodsSelect.length) {
        return;
      }
      this.submitData.ItemId = event.goodsSelect[0].ItemId;
    },

    // 新增tabs
    onAddTab() {
      this.$nextTick(() => {
        if (this.activeIndex == "add" && this.submitData.ItemId <= 0) {
          this.activeIndex = "";
          this.$message.warning("请先选择商品");
          return;
        }
      });
    },

    // 开团时间改变事件
    onChangeDate() {
      this.submitData.SaleBeginTime = this.multipleDate[0];
      this.submitData.SaleEndTime = this.multipleDate[1];
    },

    // 重置数据
    resetData() {
      let time = this.util.dateFormat("YYYY-mm-dd HH:MM:SS", new Date());
      this.multipleDate = [time, time];

      this.submitData = {
        GrouponSettingId: 0,
        ItemId: -1,
        GrouponHours: 24,
        LimitBuyCount: 0,
        SaleBeginTime: time,
        SaleEndTime: time,
        FailNextMode: 0,
        PersonGroupons: [],
      };

      this.activeIndex = "";
    },

    // 打开对话框
    async onShowDialog(event) {
      this.title = event ? "编辑拼团商品" : "新增拼团商品";
      this.submitData.GrouponSettingId = event.GrouponSettingId;
      this.initGrouponSetting();
      this.showDialog = true;
    },

    // 关闭对话框
    onCloseDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.content-box {
  .df-row {
    .tips {
      .flex-row;
      justify-content: center;
      color: #999;

      i {
        font-size: 20px;
        margin-right: 4px;
      }
    }
  }

  .el-form{
    .tips{
      color: #999;
      margin-left: 10px;
    }
  }
  .el-tabs {
    // height: var(--max-height);

    ::v-deep .el-tabs__content {
      height: 50vh;
      overflow-y: auto;
    }

    .groupon-item {
      > div {
        .flex-row;
        justify-content: center;
        width: 100px;
      }

      .attr {
        width: auto;
      }

      .price {
        width: 120px;
      }
    }
  }
}

::v-deep .down-select {
  display: none !important;
}
</style>
